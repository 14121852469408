<!--
 * @Author: filename
 * @Description: 家庭成员 - 子女信息
-->

<template>
    <div class="mt-20">
        <div v-for="(data, i) in data" :key="i">
            <Container :title="`子女 - ${data.username} - ${data.follow == 1 ? `随行` : `不随行`}`"  :is-fillet="i === 0" padding="15px">
                <el-row>
                    <el-col :span="12">
                        <span>子女名称：</span>
                        <span>{{ data.username }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>曾用名：</span>
                        <span>{{ data.used_name }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>姓名拼音：</span>
                        <span>{{ data.username_pinyin }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>性别：</span>
                        <span>{{ data.sex }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>出生日期：</span>
                        <span>{{ data.birthday }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>出生城市：</span>
                        <span>{{ data.birth_place }}</span>
                    </el-col>
                    <el-col :span="24">
                        <span>婚姻状况：</span>
                        <span>{{ data.married }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>是否在职：</span>
                        <span>{{ data.working_state }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>职位名称：</span>
                        <span>{{ data.profession }}</span>
                    </el-col>
                    <template v-if="data.follow == 1">
                        <el-col :span="12">
                            <span>国籍/地区：</span>
                            <span>{{ data.nationality }}</span>
                        </el-col>
                        <el-col :span="24">
                            <el-divider />
                        </el-col>
                        <el-col :span="12">
                            <span>是否与主申请人同住：</span>
                            <span class="red">{{ data.live_state.with_applicant }}</span>
                        </el-col>
                        <el-col :span="12"  style="align-items: normal;" v-if="data.live_state.with_applicant == '否'">
                            <span>现居住地址：</span>
                            <span>{{ data.live_state.address }}</span>
                        </el-col>
                        <el-col :span="24">
                            <el-divider />
                        </el-col>
                        <el-col :span="24">
                            <span>是否在港：</span>
                            <span class="red">{{ data.allow_in_hk.state }}</span>
                        </el-col>
                        <template v-if="data.allow_in_hk.state == '是'">
                            <el-col :span="12">
                                <span>获准逗留至：</span>
                                <span>{{ data.allow_in_hk.allow_limit_stay_at }}</span>
                            </el-col>
                            <el-col :span="12">
                                <span>在港身份：</span>
                                <span>{{ data.allow_in_hk.identity }}</span>
                            </el-col>
                        </template>
                        <el-col :span="24">
                            <el-divider />
                        </el-col>
                        <el-col :span="12">
                            <span>是否拥有抚养权：</span>
                            <span class="red">{{ data.custody.has }}</span>
                        </el-col>
                        <el-col :span="12">
                            <span>是否为前次婚姻子女：</span>
                            <span class="red">{{ data.custody.last_marriage_child }}</span>
                        </el-col>
                    </template>
                    <el-col :span="24">
                        <el-divider />
                    </el-col>
                    <el-col :span="12">
                        <span>是否办理过香港身份：</span>
                        <span class="red">{{ data.hk_id.has }}</span>
                    </el-col>
                    <el-col :span="12" v-if="data.hk_id.has == '是'">
                        <span>香港身份证：</span>
                        <span>{{ data.hk_id.number || '--' }}</span>
                    </el-col>
                </el-row>
                <template v-if="data.follow == 1">
                    <el-row v-for="(item, i) in data.certificates" :key="i">
                        <el-col :span="24">
                            <el-divider />
                        </el-col>
                        <el-col :span="24">
                            <span>证件类别：</span>
                            <span>{{ item.type }}</span>
                        </el-col>
                        <el-col :span="12">
                            <span>证件号码：</span>
                            <span>{{ item.number }}</span>
                        </el-col>
                        <el-col :span="12">
                            <span>签发地：</span>
                            <span>{{ item.issue_at }}</span>
                        </el-col>
                        <el-col :span="12">
                            <span>签发日期：</span>
                            <span>{{ item.issue_date_start_at }}</span>
                        </el-col>
                        <el-col :span="12">
                            <span>届满日期：</span>
                            <span>{{ item.issue_date_end_at }}</span>
                        </el-col>
                    </el-row>
                </template>
            </Container>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
