<!--
 * @Author: filename
 * @Description: 家庭成员信息
-->

<template>
    <div class="details-module">
        <!-- 配偶 -->
        <Spouse v-if="spouse" :data="spouse" />
        <!-- 子女 -->
        <Children  v-if="children.length > 0" :data="children" />
        <!-- 父母 -->
        <Parent v-if="parent.length > 0" :data="parent" />
        <!-- 兄弟姐妹 -->
        <!-- <BrothersAndSisters v-if="brothersAndSisters.length > 0" :data="brothersAndSisters" /> -->
    </div>
</template>

<script>
import Spouse from './C/Spouse.vue'
import Children from './C/Children.vue'
import Parent from './C/Parent.vue'
import BrothersAndSisters from './C/BrothersAndSisters.vue'
export default {
    props: {
        spouse: {
            type: Object,
            default: () => {}
        },
        children: {
            type: Array,
            default: () => []
        },
        parent: {
            type: Array,
            default: () => []
        },
        brothersAndSisters: {
            type: Array,
            default: () => []
        }
    },
    components: {
        Spouse,
        Children,
        Parent,
        BrothersAndSisters
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="less" scoped>

</style>
