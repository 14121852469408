<!--
 * @Author: filename
 * @Description: 家庭成员 - 兄弟姐妹
-->

<template>
    <div class="mt-20">
        <Container title="兄弟姐妹" padding="15px">
            <el-row  v-for="(data, i) in data" :key="i">
                <el-col :span="24" v-if="i>0">
                    <el-divider />
                </el-col>
                <el-col :span="12">
                    <span>姓名：</span>
                    <span>{{ data.username }}</span>
                </el-col>
                <el-col :span="12">
                    <span>婚姻状况：</span>
                    <span>{{ data.married }}</span>
                </el-col>
                <el-col :span="12">
                    <span>出生日期：</span>
                    <span>{{ data.birthday }}</span>
                </el-col>
                <el-col :span="12">
                    <span>出生城市：</span>
                    <span>{{ data.birth_place }}</span>
                </el-col>
                <el-col :span="12">
                    <span>性别：</span>
                    <span>{{ data.sex }}</span>
                </el-col>
                <el-col :span="12">
                    <span>职位名称：</span>
                    <span>{{ data.profession }}</span>
                </el-col>
                <el-col :span="12">
                    <span>是否办理过香港身份：</span>
                    <span class="red">{{ data.hk_id.has }}</span>
                </el-col>
                <el-col :span="12">
                    <span>香港身份证：</span>
                    <span>{{ data.hk_id.number || '--' }}</span>
                </el-col>
            </el-row>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    created(){

    },
    mounted() {
        // console.log(this.spouse)
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
