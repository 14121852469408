<!--
 * @Author: leo
 * @Description: 个人信息表
-->
<template>
    <div v-loading="isLoading" class="details-list">
        <!-- 主申请人 -->
        <User :applicant="clientInfo.applicant" />
        <!-- 家庭成员信息 -->
        <Family
            :spouse="clientInfo.spouse"
            :children="clientInfo.children"
            :parent="clientInfo.parent"
            :brothers-and-sisters="clientInfo.brothers_and_sisters"
        />
        <!-- 基本资料 -->
        <Basics :basics="clientInfo.basics" />
    </div>
</template>

<script>
import User from '../Gifted/User/User.vue'
import Family from '../Transfer/Family/Family.vue'
import Basics from '../Transfer/Basics/Basics.vue'

import { getDetails } from '../../../api/gifted'
export default {
    components: {
        User, Family, Basics,
    },
    data() {
        return {
            isLoading: false,
            clientInfo: {},
        }
    },
    created() {
        this.getData()
    },
    methods: {
        // 获取数据 分发内容
        async getData() {
            try {
                this.isLoading = true
                const res = await getDetails({
                    order_id: Number(this.$route.params.id)
                })
                if(res.code === 200) {
                    this.clientInfo = res.data
                    // console.log('this.clientInfo',this.clientInfo);
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
    },
    mounted() {
        // 双击复制
        document.addEventListener('dblclick', (e) => {
            const { outerText, localName } = e.path[0]
            if (localName === 'span') {
                const input = document.createElement("input")
                document.body.appendChild(input)
                input.value = outerText
                input.select()
                try {
                    if (document.execCommand("copy", false)) {
                        this.$message.success("复制成功!")
                    } else {
                        this.$message.success("复制失败!")
                    }
                } catch (error) {
                    console.log(error, "error")
                } finally {
                    input.remove()
                }
            }
        })
    }
}
</script>

<style lang="less" scoped>
.details-list {
    // line-height: 34px;
    >div + div {
        margin-top: 20px;
    }
    /deep/ .details-module {
        .el-row {
            padding: 0 10px;
            .el-col {
                padding: 5px 0;
                display: flex;
                align-items: center;
                line-height: 22px;
                >span:first-of-type {
                    min-width: 115px;
                    margin-right: 10px;
                    // line-height: 22px;
                }
                >span:nth-of-type(2) {
                    flex: 1;
                    word-break:break-all;
                    transition: background .1s ease-in;
                    border-radius: 6px;
                    padding-left: 8px;
                    &:hover {
                        background-color: #e7f6ff;
                    }
                }
            }
        }
    }
}
</style>
